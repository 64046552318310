import React, { MutableRefObject } from "react";
import PropTypes from "prop-types";
import * as CONSTANT from "../../utils/constant";
import { Input } from "semantic-ui-react";
import "../../css/Common.css";

const SearchBox = React.forwardRef((props: any, ref) => {
  const [inputText, setInputText] = React.useState("");
  const [inputId, setInputId] = React.useState(null);
  const [options, setOptions] = React.useState<any[]>([]);
  const [typingTimeout, setTypingTimeout] = React.useState(null);
  const [typingSearch, setTypingSearch] = React.useState(false);
  const [visible, setVisible] = React.useState(null);
  const [activeIndex, setActiveIndex] = React.useState(-1);
  const [isLoading, setIsLoading] = React.useState(false);

  const inputRef = React.useRef();
  const itemRef = React.useRef();
  const searchBoxRef = React.useRef() as MutableRefObject<HTMLDivElement>;

  React.useEffect(() => {
    // console.log("props.defaultText", props.defaultText);
    setInputText(props.defaultText);
  }, [props.defaultText]);

  React.useEffect(() => {
    setInputId(props.defaultId);
  }, [props.defaultId]);

  React.useEffect(() => {
    setOptions(props.defaultOptions);
  }, [props.defaultOptions]);

  React.useEffect(() => {
    if (options && options.length > 0 && typingSearch) {
      setVisible(true as any);
    } else {
      setVisible(false as any);
      setTypingSearch(false);
    }
    if (inputId) {
      let index = options.findIndex((item: any) => item[props.valueField] === inputId);
      if (index > -1) {
        setInputText(options[index][props.textField]);
      }
    }
  }, [options]);

  React.useEffect(() => {
    if (props.setClear) {
      setInputText("");
      setInputId(null);
      props.onClear();
    }
  }, [props.setClear]);

  React.useImperativeHandle(ref, () => ({
    getText: () => {
      return inputText;
    },
    getId: () => {
      return inputId;
    },
    getItem: () => {
      let index = options.findIndex((item: any) => item[props.valueField] === inputId);
      if (index > -1) {
        return options[index];
      } else {
        return null;
      }
    },
    setText: (text: any) => {
      // @ts-ignore
      const elm = searchBoxRef.current.querySelector(
        ".prompt.noPadding.noBorder"
      ).childNodes[0] as HTMLInputElement;
      if (elm) {
        elm.value = text;
      }
      setInputText(text);
    },
    setTextByElement: (text: any) => {
      // @ts-ignore
      const elm = searchBoxRef.current.querySelector(".prompt.noPadding.noBorder")
        .childNodes[0] as HTMLInputElement;
      if (elm) {
        elm.value = text;
      }
      setInputText(text);
    },
    setId: (id: any) => {
      setInputId(id);
    },
    setOptions: (options: any) => {
      setOptions(options);
    },
    handleSearch: (searchQuery: any) => {
      console.log(isLoading);
      setTypingTimeout(
        setTimeout(() => getDataList({ searchQuery }), 400) as any
      );
    },
    clear: () => {
      setInputText("");
      setInputId(null);
      setOptions([]);
    },
  }));

  React.useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  function handleClickOutside(event: any) {
    if (
      inputRef.current &&
      // @ts-ignore
      inputRef.current.inputRef &&
      // @ts-ignore
      inputRef.current.inputRef.current &&
      // @ts-ignore
      !inputRef.current.inputRef.current.contains(event.target) &&
      // @ts-ignore
      itemRef.current &&
      !(itemRef.current as any).contains(event.target)
    ) {
      setVisible(false as any);
      setTypingSearch(false);
    }
  }

  const handleSearch = async (e: any) => {
    setTypingSearch(true);
    const searchQuery = e.target.value;
    let searchIndex = options.findIndex(
      (items) => items[props.textField] === searchQuery
    );
    if (searchIndex > -1) {
      setInputText(options[searchIndex][props.textField]);
      setInputId(options[searchIndex][props.valueField]);
      return;
    }
    setInputText(searchQuery);
    setInputId(null);
    if (typingTimeout) {
      clearTimeout(typingTimeout as any);
    }
    setTypingTimeout(
      setTimeout(() => getDataList({ searchQuery }), 400) as any
    );

    props.onGetInputText?.(searchQuery);
  };

  const getDataList = async ({ searchQuery }: any = {}) => {
    if (searchQuery.length === 0) {
      props.onClear();
    }
    if (searchQuery.length >= props.minCharacters || props.fetchOnFocus) {
      setIsLoading(true);
      const [optionsData, optionsError] = await props.onGetSearchOptions({
        searchText: searchQuery,
      });
      if (optionsData) {
        let data = optionsData;
        if (optionsData.items) {
          data = optionsData.items;
        }
        let shortOptions = data?.slice(0, props.numberOfOptions);
        setOptions(shortOptions);
      }
      setIsLoading(false);
    }
  };

  const handleSelectItem = (e: any, index: any) => {
    if (options[index]) {
      setInputId(options[index][props.valueField]);
      setInputText(options[index][props.textField]);
      props.onSelectOption({ item: options[index], id: options[index][props.valueField] });
    }
    setVisible(false as any);
    setTypingSearch(false);
  };

  const generateDataList = () => {
    let data = [];
    for (let items in options) {
      const text = options[items][props.textField];

      data.push(
        <a
          key={`searchItem${options[items][props.valueField]}`}
          onMouseOver={() => setActiveIndex(parseInt(items))}
          className={"result " + (activeIndex === parseInt(items) ? "active" : "")}
          onClick={(e) => handleSelectItem(e, items)}
        >
          <div className="content" style={{ ...props.contentStyle }}>
            <div className="title" style={{ ...props.titleStyle }}>
              {props.toDisplay ? props.toDisplay(text) : text}
            </div>
          </div>
        </a>
      );
    }
    return data;
  };

  const handleKeyDown = (e: any) => {
    // ESC
    if (e.keyCode === CONSTANT.KEY_CODE.ESCAPE) {
      setVisible(false as any);
      setTypingSearch(false);
    } else if (
      e.keyCode === CONSTANT.KEY_CODE.ARROW_DOWN &&
      activeIndex !== options.length - 1
    ) {
      setActiveIndex(activeIndex + 1);
    } else if (e.keyCode === CONSTANT.KEY_CODE.ARROW_UP && activeIndex !== 0) {
      setActiveIndex(activeIndex - 1);
    } else if (e.keyCode === CONSTANT.KEY_CODE.ENTER) {
      handleSelectItem(e, activeIndex);
    }
  };

  let icon = {
    name: props.icon,
    link: props.linkIcon,
    onClick: props.onIconClick,
  };
  if (typeof props.icon === "object") {
    icon = props.icon;
  }

  return (
    <div ref={searchBoxRef} className="ui fluid search">
      {" "}
      {/* fluid make the dropdown fluid */}
      <Input
        readOnly={props.readOnly}
        disabled={props.disabled}
        className={
          "prompt noPadding noBorder " +
          (isLoading ? "loading" : "") +
          props.inputClassName
        }
        style={{ ...props.inputStyle }}
        onKeyDown={handleKeyDown}
        onChange={handleSearch}
        value={inputText || ""}
        ref={inputRef as any}
        icon={icon} // send icon={null} if do not want to show icon
        onFocus={() => {
          if (props.fetchOnFocus) {
            setTypingSearch(true);
            getDataList({ searchQuery: inputText });
          }
        }}
        placeholder={props.placeholder}
        error={props.isError}
        fluid={props.fluid}
      />
      <div
        ref={itemRef as any}
        className={"results transition " + (visible ? "visible" : "hidden")} // results make dropdown on top, transition show the dropdown
        style={{
          bottom: props.isDataListBottom && "120%",
          top: props.isDataListBottom && "auto",
        }}
      >
        {generateDataList()}
      </div>
    </div>
  );
});

SearchBox.defaultProps = {
  defaultText: "",
  defaultId: null,
  defaultOptions: [],
  textField: "id",
  valueField: "id",
  id: "options", // if use this component several time in a component, must pass id; otherwise, datalist will not show up
  onGetSearchOptions: () => [null, null],
  disabled: false,
  className: "",
  minCharacters: 3,
  numberOfOptions: 7,
  onSelectOption: () => {},
  icon: "search",
  inputClassName: "",
  fetchOnFocus: false,
  linkIcon: false,
  onIconClick: () => {},
  onClear: () => {},
  contentStyle: {},
  titleStyle: {},
  inputStyle: {},
  readOnly: false,
  setClear: false,
  placeholder: "",
  isDataListBottom: false,
  isError: false,
  fluid: false,
  onGetInputText: () => {},
};

SearchBox.propTypes = {
  defaultText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultOptions: PropTypes.array,
  textField: PropTypes.string,
  valueField: PropTypes.string,
  onGetSearchOptions: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  className: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  minCharacters: PropTypes.number,
  numberOfOptions: PropTypes.number,
  onSelectOption: PropTypes.func,
  // icon: PropTypes.string,
  inputClassName: PropTypes.string,
  fetchOnFocus: PropTypes.bool,
  readOnly: PropTypes.bool,
  linkIcon: PropTypes.bool,
  onIconClick: PropTypes.func,
  onClear: PropTypes.func,
  contentStyle: PropTypes.object,
  titleStyle: PropTypes.object,
  inputStyle: PropTypes.object,
  setClear: PropTypes.bool,
  testid: PropTypes.string,
  placeholder: PropTypes.string,
  isDataListBottom: PropTypes.bool,
  isError: PropTypes.bool,
  fluid: PropTypes.bool,
  onGetInputText: PropTypes.func,
  toDisplay: PropTypes.func,
};

SearchBox.displayName = "SearchBox"
export default React.memo(SearchBox);
